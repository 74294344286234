import { useRef, useState } from "react";
import { useAuth } from "../authcontext";
import "../../assets/css/access.css"
import logo from "../../assets/img/logo-white.png";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("Visita tu correo electrónico y sigue las instrucciones.");
        } catch {
            setError("Algo salió mal. Intenta de nuevo...");
        }

        setLoading(false);
    }

    return(
        <div className="access-box">
            <div className="access-box-centered">
                <img src={logo} alt={logo} />
                <div className="card access-card">
                    <h2 className="text-center mb-4 title">Iniciar Sesión</h2>

                    <form onSubmit={handleSubmit}>
                        {error && <div>{error}</div>}
                        {message && <div>{message}</div>}
                        <input type="email" ref={emailRef} placeholder="Correo Electrónico" />
                        <button className="btn btn-primary" disabled={loading} type="submit">Enviar Correo</button>
                        <Link to="/login">Ya recordé mi contraseña</Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;