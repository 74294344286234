const useiGitBackAPI = () => {
    const api = {
        //currentServer: "http://localhost/igitback/API/v1/frutamiaClients/",
        currentServer: "https://frutamia.com.mx/igitback/API/v1/frutamiaClients/",
        getAllActiveProducts: async () => {
            return await fetch(`${api.currentServer}getAllActiveProducts`).then(response => response.json());
        },
        getStoreByUserId: async (userId) => {
            return await fetch(`${api.currentServer}getStoreByUserId`,{
                method: "post",
                headers: {
                    //'Content-Type': 'application/json'
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `userId=${userId}`//JSON.stringify({userId})
            }).then(response => response.json());
        },
        setNewOrder: async (data) => {
            return await fetch(`${api.currentServer}setNewOrder`,{
                method: "post",
                headers: {
                    //'Content-Type': 'application/json'
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `order=${JSON.stringify(data)}`
            }).then(response => response.text());
        }
    };

    return api;
};

export default useiGitBackAPI;