import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const app = firebase.initializeApp({
    apiKey: "AIzaSyCnxfxi5Gru0-TM0m-7iAwvkQl8Hj557ro",
    authDomain: "frutamia-dev.firebaseapp.com",
    projectId: "frutamia-dev",
    storageBucket: "frutamia-dev.appspot.com",
    messagingSenderId: "949370637769",
    appId: "1:949370637769:web:d13c2378623a33d267ac18",
    measurementId: "G-CW66XCR2PZ"
})

export const auth = app.auth();
export const db = app.firestore();
export default app;