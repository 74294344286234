import ProductsList from "./productsList"
import PromoList from "./promoList";
import "../../assets/css/orders.css";
import { useState } from "react";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../general/modal";
import { useAuth } from "../../authentication/authcontext";
import useiGitBackAPI from "../../API/igitback";
import { useHistory } from "react-router-dom";

const Orders = () => {
    const [ list, setList ] = useState([]);
    const [ modalActive, setModalActive ] = useState(false);
    const { currentUser } = useAuth();
    const { getStoreByUserId, setNewOrder } = useiGitBackAPI();
    const [ loadingOff, setLoadingOff ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const history = useHistory();

    function toggleModal(){
        const toggle = modalActive;
        setModalActive(!toggle);
    }

    function handleOrder(){
        if(list.length > 0){
            setLoading(true);
            getStoreByUserId(currentUser.uid).then(data => {
                setNewOrder({
                    userId: currentUser.uid,
                    storeId: data.id,
                    products: list.map(order => {
                        return {
                            productId: order.id,
                            amount: order.amount
                        };
                    })
                }).then(data => {
                    setLoadingOff(true);
                    setList([]);
                    setTimeout(() => {
                        history.push("/");
                    },1000);
                }); 
            });
            
        }else{
            alert("La lista no puede enviarse sin productos.");
            toggleModal();
        }
    }

    return(
        <div>
            <div className="order-wrap">
                <div className="up-side">
                    {/*<PromoList />*/}
                    <ProductsList list={list} setList={setList} />
                </div>
                <button className="btn btn-primary down-side" onClick={toggleModal}>Realizar Pedido</button>
            </div>
            <Modal active={modalActive}>
                { !loadingOff && <ModalHeader>
                    <h2 className="title">Confirma tu pedido</h2>
                    <p style={{textAlign:"center"}}>Este es tu resumen de pedido</p>
                    <p style={{textAlign:"center"}}><strong>Importante: Pide antes de las 9pm y recibe tu pedido mañana.</strong></p>
                    <p style={{textAlign:"center"}}><strong>¿Olvidaste algo?</strong></p>
                </ModalHeader>}
                <ModalBody>
                    { list.sort((a,b) => a.name.localeCompare(b.name)).map((product) => {
                        return(
                            <div key={product.id} className="ticket-item">
                                <div className="product">{product.name}</div>
                                <div className="amount">{product.amount} kg</div>
                            </div>
                        );
                    }) }
                    {
                        loadingOff && <div className="success"><i className="fas fa-check"></i><p>Pedido Enviado</p></div>
                    }
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-small btn-primary" disabled={loading} onClick={handleOrder}>Finalizar Pedido</button>
                    <button className="btn btn-small btn-black" onClick={toggleModal}>Seguir Pidiendo</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Orders;