import "../../assets/css/modal.css";

const Modal = ({active,children}) => {
    return(
        <div className={ active ? "modal-wrap active":"modal-wrap" }>
            <div className={ active ? "modal-background active":"modal-background" }></div>
            <div className={ active ? "modal active":"modal" }>
                {children}
            </div>
        </div>
    );
};
export default Modal;

export const ModalHeader = ({children}) => {
    return <div className="modal-header">{children}</div>;
};

export const ModalBody = ({children}) => {
    return <div className="modal-body">{children}</div>;
};

export const ModalFooter = ({children}) => {
    return <div className="modal-footer">{children}</div>
};