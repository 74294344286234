import ProductItem from "../general/productItem";
import "../../assets/css/products.css";
import { useEffect, useState } from "react";
import useiGitBackAPI from "../../API/igitback";

const ProductsList = ({list,setList}) => {
    const { getAllActiveProducts } = useiGitBackAPI();
    const [ productsList, setProductsList ] = useState([]);

    useEffect(() => {
        getAllActiveProducts().then((data) => {
            setProductsList(data);
        });
    },[getAllActiveProducts]);

    function onInputChange(id,sku,image,name,amount){
        let orderList = list;

        const productIndex = orderList.findIndex((product) => {return product.id === id});
        if(productIndex > -1){
            if(amount)
                orderList[productIndex].amount = amount;
            else{
                orderList.splice(productIndex, 1);
            }  
        }else
            orderList.push({id,sku,image,name,amount});

        //console.log(orderList);
        setList(orderList);
    }

    return(
        <div className="products-list">
            <p>Realiza tu pedido para mañana</p>
            { 
                productsList && productsList.map((product) => {
                    return(<ProductItem key={product.id} image={product.imageUrl !== '' ? `https://frutamia.com.mx${product.imageUrl}` : "https://frutamia.com.mx/catalogue/products/none.webp"} sku={product.sku} name={product.name} id={product.id} onChange={onInputChange} />);
                })
            }
        </div>
    );
};

export default ProductsList;