import "../assets/css/theme.css";
import logo from "../assets/img/logo-white.png";
import { useState } from "react";
import { Link, NavLink, Route, Switch, useHistory } from "react-router-dom";
import { useAuth } from "../authentication/authcontext";
import Orders from "./orders/orders";
import Shippings from "./shippings/shippings";
import OrdersList from "./orders/ordersList";

const Theme = () => {
    const { currentUser, logout } = useAuth();
    const [ menuActive, setMenuActive ] = useState("");
    const [ error, setError ] = useState("");
    const history = useHistory();

    function toggleMenu(){
        if(menuActive === "") setMenuActive("active");
        else setMenuActive("");
    }

    async function handleLogout() {
        setError("");

        try {
            await logout();
            history.push("/login");
        } catch {
            setError("Failed to log out");
        }
    }

    return(
        <div className="theme-box">
            <header>
                <div className="menu-btn">
                    <button className="btn btn-primary" onClick={toggleMenu}><i className="fas fa-bars" /></button>
                </div>
                <div className="left">
                    <Link to="/" className="logo">
                        <img src={logo} alt="frutamía" />
                    </Link>
                </div>
                <div className="right">
                    { error && <div>{error}</div>}
                    <button className="btn btn-primary" title={currentUser.email}><i className="fas fa-user" /></button>
                    <button className="btn btn-secundary" onClick={handleLogout}><i className="fas fa-sign-out-alt" /></button>
                </div>
            </header>
            <div className="theme-body">
                <nav className={`navbar ${menuActive}`}>
                    <ul className="menu">
                        <li><button className="btn btn-black"><i className="fas fa-shopping-cart"></i></button></li>
                    </ul>
                    <ul className="submenu">
                        <li><NavLink to="/app/orders" onClick={toggleMenu}><i className="fas fa-cart-plus" /> Nuevo Pedido</NavLink></li>
                        <li><NavLink to="/app/history" onClick={toggleMenu}><i className="fas fa-cart-arrow-down" /> Pedido Actual</NavLink></li>
                        <li><NavLink to="/app/shippings" onClick={toggleMenu}><i className="fas fa-truck" /> Envío</NavLink></li>
                    </ul>
                </nav>
                <div className="container">
                    <Switch>
                        <Route exact path="/app" component={Orders} />
                        <Route path="/app/orders" component={Orders} />
                        <Route path="/app/shippings" component={Shippings} />
                        <Route path="/app/history" component={OrdersList} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default Theme;