import { useState } from "react";

const ProductItem = ({sku,name,id,image,onChange}) => {
    const [ amount, setAmount ] = useState("");

    function onInputChange(e){
        setAmount(parseInt(e.target.value));
        
        onChange(id,sku,image,name,e.target.value);
    }

    return(
        <div className="product-item" id={sku}>
            <div className="image"><img src={image} alt={image} /></div>
            <div className="name">
                <span>SKU: {sku}</span>
                <p>{name}</p>
            </div>
            <div className="amount"><label><input type="number" onChange={onInputChange} value={amount} placeholder="0" /><p> kg</p></label></div>
        </div>
    );
};

export default ProductItem;